import '../styles/global.css';

import { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';

export default function App({
  Component,
  pageProps,
  router,
  err,
}: AppProps | any) {
  return (
    <>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, viewport-fit=cover'
        ></meta>
      </Head>
      <DefaultSeo
        openGraph={{
          type: 'website',
          locale: 'en-US',
          url: 'https://www.defensepestaustin.com/',
          site_name: 'Defense Pest Management - Austin,TX',
          images: [
            {
              url: '/OGImage.jpg',
              alt: 'Defense Pest Management - Austin, TX',
            },
          ],
        }}
        titleTemplate={'%s | Defense Pest Management - Austin, TX'}
        defaultTitle='Defense Pest Management - Austin, TX'
      />
      <Component {...pageProps} err={err} />
      <div id='modal-portal' />
      <Toaster
        toastOptions={{
          style: {},
        }}
      />
    </>
  );
}
